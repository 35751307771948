/* Mobile-first base styles */
.rating-page {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
  background: #fff;
  min-height: 100vh;
}

/* Book Header */
.book-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.book-cover {
  width: 120px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.book-info h1 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  color: #2d3748;
}

.book-info h2 {
  font-size: 1rem;
  color: #718096;
  margin: 0;
  font-weight: normal;
}

/* Form Styles */
.rating-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
}

.form-group input[type="text"],
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.2s;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

/* Star Rating Component */
.star-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  user-select: none;
  touch-action: none;
}

.star-container {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.star {
  width: 100%;
  height: 100%;
  transition: transform 0.1s;
}

.star:hover {
  transform: scale(1.1);
}

.rating-value {
  margin-left: 0.5rem;
  font-weight: 500;
  color: #4a5568;
}

/* Tag Selector Component */
.tag-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tag-search {
  position: relative;
}

.tag-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 999px;
  background: #fff;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
}

.tag-button:hover {
  background: #f7fafc;
}

.tag-button.selected {
  background: #4299e1;
  color: white;
  border-color: #4299e1;
}

.selected-tags {
  margin-top: 1rem;
}

.selected-tags h4 {
  margin: 0 0 0.5rem 0;
  color: #4a5568;
}

.selected-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.selected-tag {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  background: #4299e1;
  color: white;
  border-radius: 999px;
  font-size: 0.875rem;
}

.remove-tag {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
}

/* Recommend Checkbox */
.recommend-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.recommend-checkbox input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
}

/* Submit Button */
.submit-button {
  background: #4299e1;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  margin-top: 1rem;
}

.submit-button:hover {
  background: #3182ce;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  color: #4299e1;
  font-size: 1.25rem;
}

/* Error State */
.error {
  text-align: center;
  padding: 2rem;
  color: #4a5568;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.error h2 {
  color: #2d3748;
  margin: 0;
}

.back-button {
  background: #4299e1;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  margin-top: 1rem;
}

.back-button:hover {
  background: #3182ce;
}

/* Success State */
.success {
  text-align: center;
  padding: 2rem;
}

.rating-stats {
  margin-top: 2rem;
  padding: 1rem;
  background: #f7fafc;
  border-radius: 0.5rem;
}

.popular-tags {
  margin-top: 1rem;
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 0.5rem;
}

.tag {
  padding: 0.25rem 0.75rem;
  background: #e2e8f0;
  border-radius: 999px;
  font-size: 0.875rem;
  color: #4a5568;
}

/* Tablet and Desktop Styles */
@media (min-width: 768px) {
  .rating-page {
    max-width: 768px;
    padding: 2rem;
  }

  .book-cover {
    width: 180px;
  }

  .book-info h1 {
    font-size: 2rem;
  }

  .book-info h2 {
    font-size: 1.25rem;
  }

  .star-container {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form-group {
    gap: 0.75rem;
  }
}