@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles should come after Tailwind */
@import './App.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.responsive-image-container {
  position: relative; /* Make the container a positioned element */
  overflow: hidden; /* Ensure the image doesn't move outside the container */
  max-height: 300px; /* Maximum height for the container */
  width: 100%;
}

.responsive-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.8s ease-out; /* Smooth transition for the sliding effect */
  max-height: 300px; /* Keep the image height in check */
}

@media (max-width: 768px) {
  .responsive-image {
    transform: translateY(-10px); /* Adjust the upward movement to a smaller value */
  }
}
