/* Main Container */
.bookshelf-container {
  max-width: 1400px;
  margin: 40px auto;
  padding: 0 20px;
  perspective: 1000px;
}

/* Book Frame */
.book-frame {
  background: linear-gradient(to bottom, #2a1810, #1a0f0a);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 
    0 0 50px rgba(0,0,0,0.8),
    inset 0 0 20px rgba(0,0,0,0.5);
}

.frame-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(to right, #1a0f0a, #2a1810, #1a0f0a);
  border: 2px solid #3d2815;
  border-radius: 5px;
  margin-bottom: 20px;
}

.frame-title {
  font-family: 'Cinzel Decorative', cursive;
  font-size: 2.5em;
  color: #c9a959;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.frame-ornament {
  width: 50px;
  height: 50px;
}

.ornament-circle {
  fill: none;
  stroke: #c9a959;
  stroke-width: 2;
}

.ornament-cross {
  stroke: #c9a959;
  stroke-width: 2;
}

.frame-support {
  position: absolute;
  bottom: -10px;
  width: 100px;
  height: 20px;
  background: #2a1810;
  border: 2px solid #3d2815;
}

.frame-support.left {
  left: 20px;
  transform: skew(-20deg);
}

.frame-support.right {
  right: 20px;
  transform: skew(20deg);
}

/* Enhanced Book Styles */
.enhanced-book {
  width: clamp(30px, 4vw, 45px);
  height: var(--random-height, 200px);
  margin: 0 3px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(var(--random-rotation, 0deg));
  cursor: pointer;
  transition: transform 0.3s ease;
}

.book-spine {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #1a1a1a, #2a2a2a);
  border-radius: 3px 0 0 3px;
  padding: 15px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform-origin: right;
  transform: rotateY(0deg);
  backface-visibility: hidden;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
}

.book-spine-title {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: #e0c080;
  font-family: 'Cinzel', serif;
  font-size: clamp(0.8em, 1.2vw, 1.1em);
  font-weight: 600;
  text-align: center;
  margin: auto;
  text-shadow:
    2px 2px 2px rgba(0,0,0,0.8),
    0 0 5px rgba(201, 169, 89, 0.3);
  letter-spacing: 1px;
  line-height: 1.2;
}

.book-spine-author {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: #a08b6c;
  font-size: clamp(0.65em, 1vw, 0.85em);
  margin-top: 8px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
  font-weight: 500;
}

.book-spine-decoration {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #c9a959, transparent);
}

.book-spine-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 30px,
    rgba(201, 169, 89, 0.1) 30px,
    rgba(201, 169, 89, 0.1) 31px
  );
}

.book-cover {
  position: absolute;
  width: 120%;
  height: 100%;
  right: -120%;
  background: #2a2a2a;
  transform: rotateY(90deg);
  transform-origin: left;
}

.book-cover-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 L20,20 M20,0 L0,20' stroke='rgba(201,169,89,0.1)' fill='none' /%3E%3C/svg%3E");
  opacity: 0.5;
}

.book-cover-shine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 40%, rgba(255,255,255,0.1) 50%, transparent 60%);
}

.book-pages {
  position: absolute;
  right: -5px;
  width: 5px;
  height: 100%;
  background: #fff;
  transform: rotateY(90deg);
  transform-origin: left;
}

.book-binding {
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

/* Spooky Effects */
.floating-orb {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 
    0 0 10px rgba(255,255,255,0.3),
    0 0 20px rgba(255,255,255,0.2),
    0 0 30px rgba(255,255,255,0.1);
  pointer-events: none;
}

.fog-effect {
  position: absolute;
  width: 200px;
  height: 100px;
  background: radial-gradient(
    circle at center,
    rgba(255,255,255,0.1) 0%,
    transparent 70%
  );
  filter: blur(8px);
  pointer-events: none;
}

.spider-web {
  position: absolute;
  width: 120px;
  height: 120px;
  pointer-events: none;
  opacity: 0.5;
}

.dead-tree {
  position: absolute;
  width: 100px;
  height: 200px;
  pointer-events: none;
}

.dead-tree.left {
  left: -120px;
  top: 50%;
  transform: translateY(-50%);
}

.dead-tree.right {
  right: -120px;
  top: 50%;
  transform: translateY(-50%) scaleX(-1);
}

/* Shelf Container */
.shelves-container {
  display: flex;
  flex-direction: column;
  gap: clamp(30px, 5vh, 60px);
  transform-style: preserve-3d;
  position: relative;
  padding: clamp(10px, 2vw, 20px);
}

/* Individual Shelf */
.shelf {
  background: linear-gradient(to bottom, #2a1810, #1a0f0a);
  padding: clamp(15px, 2vw, 25px);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: clamp(200px, 30vh, 250px);
  position: relative;
  transform-style: preserve-3d;
  box-shadow:
    0 10px 20px rgba(0,0,0,0.4),
    inset 0 0 50px rgba(0,0,0,0.5);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.shelf::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

/* Add padding for mobile scroll area */
@media (max-width: 768px) {
  .shelf {
    padding: 15px 40px;
  }
  
  .shelf::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to left, #1a0f0a 0%, transparent 100%);
    pointer-events: none;
    z-index: 2;
  }
  
  .shelf::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, #1a0f0a 0%, transparent 100%);
    pointer-events: none;
    z-index: 2;
  }
}

.shelf::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, 
      rgba(255,255,255,0.03) 0px,
      transparent 1px
    ) 0 0 / 20px 100%;
  pointer-events: none;
}

.shelf::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.4));
  pointer-events: none;
}

/* Empty Shelf Space */
.empty-shelf-space {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: repeating-linear-gradient(
    45deg,
    rgba(0,0,0,0.1),
    rgba(0,0,0,0.1) 10px,
    rgba(0,0,0,0.2) 10px,
    rgba(0,0,0,0.2) 20px
  );
}

/* Book Detail Modal */
.book-detail-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, #2a1810, #1a0f0a);
  border: 2px solid #3d2815;
  border-radius: 15px;
  padding: 30px;
  width: min(90vw, 800px);
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  box-shadow:
    0 0 50px rgba(0,0,0,0.8),
    inset 0 0 20px rgba(0,0,0,0.5);
}

.book-detail-modal::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.book-detail-header {
  margin-bottom: 25px;
  border-bottom: 2px solid #3d2815;
  padding-bottom: 15px;
}

.book-detail-content {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.book-media {
  flex-shrink: 0;
  width: clamp(120px, 30%, 250px);
}

.book-cover-placeholder {
  width: 100%;
  aspect-ratio: 2/3;
  background: linear-gradient(135deg, #2a1810, #1a0f0a);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  color: #e0c080;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
  box-shadow: 0 5px 15px rgba(0,0,0,0.4);
}

.book-info {
  flex: 1;
  padding: 0 20px;
}

.info-section {
  margin-bottom: 20px;
}

.info-section h3 {
  font-family: 'Cinzel', serif;
  color: #e0c080;
  font-size: 1.2em;
  margin-bottom: 8px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.info-section p {
  color: #a08b6c;
  font-size: 1em;
  line-height: 1.6;
}

.book-title {
  font-family: 'Cinzel', serif;
  font-size: clamp(1.5em, 3vw, 2.2em);
  color: #e0c080;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
}

.edit-title-input {
  font-size: 1.5em !important;
  width: 100%;
  margin-bottom: 0 !important;
}

.edit-input {
  margin-bottom: 15px !important;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.book-description {
  color: #c9a959;
  font-size: clamp(0.85em, 1.4vw, 1em);
  line-height: 1.6;
  margin: 20px 0;
  padding: 15px;
  background: rgba(0,0,0,0.2);
  border-radius: 8px;
  border-left: 3px solid #3d2815;
}

.book-cover {
  width: clamp(100px, 25%, 200px);
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.4);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #3d2815;
}

.edit-textarea {
  width: 100%;
  min-height: 150px;
  background: rgba(0,0,0,0.2);
  border: 1px solid #3d2815;
  border-radius: 8px;
  padding: 12px;
  color: #c9a959;
  font-size: 0.9em;
  line-height: 1.5;
  resize: vertical;
  margin: 15px 0;
}

.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1a0f0a;
  border: 2px solid #3d2815;
  border-radius: 10px;
  padding: 20px;
  z-index: 1100;
  text-align: center;
  box-shadow: 0 0 30px rgba(0,0,0,0.8);
}

.confirm-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .frame-title {
    font-size: 1.8em;
  }
  
  .dead-tree {
    display: none;
  }

  .book-detail-modal {
    padding: 20px;
  }

  .book-info {
    padding: 15px 0;
  }

  .modal-actions {
    flex-direction: column-reverse;
    gap: 15px;
  }

  .modal-actions > * {
    width: 100%;
  }

  .modal-actions button {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .frame-title {
    font-size: 1.4em;
  }
  
  .spider-web {
    width: 80px;
    height: 80px;
  }

  .book-detail-modal {
    padding: 15px;
  }

  .book-cover {
    width: 100%;
    margin-bottom: 15px;
  }

  .book-info {
    padding: 10px 0;
  }

  .book-detail-modal > div:first-child {
    flex-direction: column;
  }
}